{
  "name": "app-relayter-web",
  "version": "2.36.6",
  "license": "MIT",
  "description": "The webapp for Relayter",
  "contributors": [
    {
      "name": "Boris Visser",
      "email": "borisvisser@relayter.com",
      "url": "https://www.relayter.com"
    },
    {
      "name": "Simon Windt",
      "email": "simon@relayter.com",
      "url": "https://www.relayter.com"
    },
    {
      "name": "Jiamei Wu",
      "email": "jiameiwu@relayter.com",
      "url": "https://www.relayter.com"
    },
    {
      "name": "Marcel Hoogesteger",
      "email": "marcelhoogesteger@relayter.com",
      "url": "https://www.relayter.com"
    }
  ],
  "engines": {
    "node": ">= 20.12"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 1337 --live-reload false",
    "start-dev": "ng serve --port 1337 --configuration dev",
    "build": "ng build --configuration production",
    "build-dev": "ng build --progress --configuration dev",
    "build-dev-pd": "ng build --progress --configuration dev-pd",
    "build-dev-csd": "ng build --progress --configuration dev-csd",
    "build-accept": "ng build --progress --configuration accept",
    "build-prod": "ng build --progress --configuration production",
    "lint": "ng lint app-relayter-web",
    "version": "echo $npm_package_version",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org relayter --project app-relayter-web ./dist && sentry-cli sourcemaps upload --org relayter --project app-relayter-web ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.8",
    "@angular/cdk": "18.2.9",
    "@angular/common": "18.2.8",
    "@angular/compiler": "18.2.8",
    "@angular/compiler-cli": "18.2.8",
    "@angular/core": "18.2.8",
    "@angular/forms": "18.2.8",
    "@angular/localize": "18.2.8",
    "@angular/material": "18.2.9",
    "@angular/platform-browser": "18.2.8",
    "@angular/platform-browser-dynamic": "18.2.8",
    "@angular/router": "18.2.8",
    "@auth0/angular-jwt": "5.2.0",
    "@auth0/auth0-spa-js": "1.22.6",
    "@codemirror/autocomplete": "^6.18.4",
    "@codemirror/commands": "^6.8.0",
    "@codemirror/lang-javascript": "^6.2.2",
    "@codemirror/lint": "^6.8.4",
    "@codemirror/state": "^6.5.1",
    "@codemirror/theme-one-dark": "^6.1.2",
    "@codemirror/view": "^6.36.2",
    "@relayter/core": "16.0.1",
    "@relayter/rubber-duck": "14.4.3",
    "@rive-app/canvas": "^2.23.8",
    "@sentry/angular": "^8.54.0",
    "@sentry/cli": "^2.41.1",
    "chart.js": "^3.0.1",
    "codemirror": "^6.0.1",
    "color": "4.2.3",
    "date-fns": "^4.1.0",
    "date-fns-tz": "^3.2.0",
    "escape-string-regexp": "^5.0.0",
    "eslint-linter-browserify": "^9.18.0",
    "fast-xml-parser": "^4.5.0",
    "globals": "^15.14.0",
    "i18n-iso-countries": "7.12.0",
    "jshint": "^2.13.6",
    "ng-table-virtual-scroll": "1.6.0",
    "ng2-charts": "3.0.1",
    "ngx-filesize": "^3.0.4",
    "ngx-infinite-scroll": "18.0.0",
    "ngx-papaparse": "8.0.0",
    "number-to-words": "1.2.4",
    "pixi.js": "4.8.9",
    "rxjs": "7.8.1",
    "socket.io-client": "4.7.2",
    "tslib": "^2.0.0",
    "uuid": "^11.0.2",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/build": "^18.2.9",
    "@angular/cli": "^18.2.9",
    "@segment/analytics-next": "^1.76.0",
    "@types/chart.js": "^2.9.3",
    "@types/color": "^3.0.2",
    "@types/pixi.js": "4.8.9",
    "@types/uuid": "3.4.6",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "cerialize": "0.1.18",
    "eslint": "^8.57.1",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-prefer-arrow": "latest",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-rxjs-angular": "^2.0.1",
    "sass-loader": "14.1.0",
    "ts-node": "8.6.2",
    "typescript": "5.5.4"
  },
  "nyc": {
    "include": [
      "test/**/*.ts",
      "test/**/*.tsx"
    ],
    "extension": [
      ".ts",
      ".tsx"
    ],
    "require": [
      "ts-node/register"
    ],
    "reporter": [
      "lcov",
      "html",
      "text",
      "text-summary"
    ],
    "sourceMap": true,
    "instrument": true,
    "report-dir": "./docs/unit-test-coverage"
  }
}
